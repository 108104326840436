import React from "react"
import InteriorHeader from "../components/interior-header";
import SEO from "../components/seo";
import Layout from "../components/layout"
import AboutCta from "../components/about-cta";
import AboutBodyCopy from "../components/about-body-copy";
import useAboutBodyCopy from "../hooks/use-aboutbodycopy";
import animation from '../animations/about-hero.json';


const AboutPage = () => {
  return (
    <Layout>
      <SEO title={`About`} description="Creativity is built into our DNA. We believe that technology can be a means of enhancing and expanding on our innate human qualities of imagination, innovation, social connection, and productivity"/>
      <InteriorHeader pageLocation={`about-interior-header`} animation={animation}/>
      <AboutCta hideTitle={true} hasImage={false}/>
      <AboutBodyCopy data={useAboutBodyCopy()}/>
    </Layout>
  )
}

export default AboutPage
