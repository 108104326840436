import React from "react";


const AboutBodyCopy = (props) => {
  const data = props.data;

  const renderImageBlock = (data, start, stop) => {
    return (
      <>
        {
          data.body_images.slice(start, stop).map((items, index) => (
            <div key={items['id']} className="about-body-copy__images-wrapper">
              <picture>
                <source media="(max-width: 767px)" srcSet={items['mobile_image']}/>
                <source media="(min-width: 768px)" srcSet={items['desktop_image']}/>
                <img className="about-body-copy__images" src={items['mobile_image']} alt=""/>
              </picture>
            </div>
          ))
        }
      </>
    )
  }
  return (
    <section className="about-body-copy">
      <div className="about-body-copy__images-container">
        <div className="about-body-copy__images-item">
          {renderImageBlock(data, 0, 2)}
        </div>
        <div className="about-body-copy__images-item">
          {renderImageBlock(data, 2, 4)}
        </div>
      </div>
      <div className="container">
        <div className="about-body-copy__text text-center">
          <div className="about-body-copy__section-title section-title">{data.title}</div>
          <div className="about-body-copy__description body-copy">
            {data.description}
          </div>
          <div className="about-body-copy__cta-wrapper">
            <a href={data.url} className="button button--green-ghost" target="_blank" rel="noopener noreferrer">{data.button_text}</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutBodyCopy;
