import { useStaticQuery, graphql } from 'gatsby';

const useAboutBodyCopy = () => {
  const data = useStaticQuery(graphql`
    {
      allAboutBodyCopyJson {
        nodes {
          body_images {
            id
            desktop_image
            mobile_image
          }
          title
          description
          url
          button_text
        }
      }
    }
  `);

  return data.allAboutBodyCopyJson.nodes[0];
};

export default useAboutBodyCopy;
